// import { AppBar, Toolbar, Typography, Container, Button, Box } from '@mui/material';
// import { Link } from 'react-router-dom';

// import Logo from "../logos/OmneVu logo.png";

// export default function NavBar() {

//     return (
//         <>

//             <AppBar position="static"
//                    sx={{
//                     // bgcolor: 'white',
//                     // color: 'primary.main',
//                     fontWeight: 'bold',
//                     width:'100%',
//                     // maxWidth:600,
//                     mx:'auto'
//                     // display:'flex'
//                 }}
//               >
                
//                     <Toolbar
//                    >
//                         {/* <Typography variant="h6" sx={{ flexGrow: 1 }}>
//               OmneVu.com
//             </Typography> */}
//                         <Box sx={{bgcolor:'white'}}>
//                         <Link to="/"><Box
//                             component="img"
//                             sx={{
//                                 height: 50,
//                                 width: 75,
//                                 display: 'flex',
//                                 flexDirection: 'row',
//                                 justifyContent: 'flex-start',

//                             }}
//                             alt="OmneVu.com"
//                             src={Logo}

//                         />
//                         </Link>
//                         </Box>
//                         <Box width='60%' sx={{ml:3}}>
//                             <Typography variant='h4' fontWeight='bold'>OmnéVu</Typography>
//                         </Box>
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'right',
                                
                                

//                             }}
//                         >

//                             <Button color="inherit" component={Link} to="/">Home</Button>
//                             <Button color="inherit" component={Link} to="/products">Products</Button>
//                             {/* <Button color="inherit" component={Link} to="/services">Services</Button> */}
//                             <Button color="inherit" component={Link} to="/about">About Us</Button>
//                             <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
//                         </Box>
//                     </Toolbar>
                
//             </AppBar>


//         </>
//     )

// }


import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from "../logos/OmneVu logo.png";

export default function NavBar() {

    return (
        <>
            <AppBar 
                position="sticky"
                sx={{
                    background: 'linear-gradient(to right, #ADD8E6, #007BFF)', // Blue gradient
                    color: 'white', // Text color
                    fontWeight: 'bold',
                    padding: '5px 20px', // Reduced padding for a smaller height
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Slight shadow for a modern effect
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '50px' }}>
                    {/* Logo Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link to="/">
                            <Box
                                component="img"
                                sx={{
                                    height: 40, // Reduced logo height
                                    width: 60, // Reduced logo width
                                    marginRight: 2, // Space between logo and title
                                }}
                                alt="OmneVu.com"
                                src={Logo}
                            />
                        </Link>
                        <Typography 
    variant="h5" 
    fontWeight="bold" 
    sx={{ 
        fontFamily: 'Poppins, sans-serif', 
        color: '#000080'// Blue color for the text
    }}
>
    OmnéVu
</Typography>

                    </Box>

                    {/* Navbar Links Section */}
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <Button color="inherit" component={Link} to="/">Home</Button>
                        <Button color="inherit" component={Link} to="/products">Products</Button>
                        <Button color="inherit" component={Link} to="/about">About Us</Button>
                        <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}
