import React from 'react';
import { Card, CardMedia, CardActionArea, Typography, Box, Divider } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

// Import the logos
import OVPlanLogo from '../logos/OV-Plan.png';
import OVTimeLogo from '../logos/OV-Timer.png';
import OVRetailLogo from '../logos/OV-Retail.png';
import OVIMSLogo from '../logos/OV-inventory.png';
import OVWMSLogo from '../logos/OV-WarrantyMS.png';
import OVEventLogo from '../logos/OV-EventMgmt.png';
import OVProfileLogo from '../logos/OVProfile.png';
import OVSurewayLogo from '../logos/OV-Sureway.png';
import OVNewsLogo from '../logos/OV-NewsLetter.png';
import OVGuruLogo from '../logos/OVGuru.png';

// Array of logos for the carousel
const logos = [
  { src: OVPlanLogo, title:"Agile, Planning, CRM, Queue Management" },
  // { src: OVTimeLogo, title: 'OV Time' },
  { src: OVTimeLogo, title:"Manage Time Booking, Leaves, Avaialbaility for co-located and/or distributed teams with a Geofencing feature"},
  { src: OVRetailLogo, title:"Manage day to day operatons for your Office / Branches, Retail establishements, NGOs or Professional forums"},
  { src: OVIMSLogo, title:"Manage your Inventory on the go. Get an Inventory financial view and Green Quotient at click of button"},
  { src: OVWMSLogo, title:"Manage Warranty, Post/After Sale serivce for a Retailer / Manufacturer or Track warranties of your products you purchased as customer"},
  { src: OVEventLogo, title:"Plan, schedule, promote and track bookings/attendance of your online and/or offline Events"},
  { src: OVProfileLogo, title:"Manage, Search capture, store your business relations as well as public facing profile of your business here"},
  { src: OVSurewayLogo, title:"Conduct, set-up, track, manage Online / Offline Market Surveys, Evaluation and assessments for your businesses or training or educational institutes"},
  { src: OVNewsLogo, title:"Create, Share, Manage or distribute your newsletters or regular news broadcasts to a closed group at the click of button"},
  { src: OVGuruLogo, title:"Create, Record, Manage, Monetize, Share your knowledge for closed or public groups"}
];

const CarouselComponent = () => {
  return (
    <Box sx={{ mt: 2, width:'100%'}}>
      <Divider></Divider>
      <Carousel autoPlay={true} animation="slide" interval={3000} navButtonsAlwaysVisible={false}>
        {logos.map((logo, index) => (
          <Card key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={logo.src}
                alt={logo.title}
                sx={{ objectFit: 'contain', maxHeight: '200px' }}
              />
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body" fontSize={18} fontWeight={'fontWeightBold'}>{logo.title}</Typography>
              </Box>
            </CardActionArea>
          </Card>
        ))}
      </Carousel>
      
    </Box>
  );
};

export default CarouselComponent;
