import React from 'react';
import { Card, CardMedia, Typography, CardContent, Stack, Box, Paper, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';

    import OVPlanLogo from '../logos/OV-Plan.png';
    import OVTimeLogo from '../logos/OV-Timer.png';
    import OVRetailLogo from '../logos/OV-Retail.png';
    import OVIMSLogo from '../logos/OV-inventory.png';
    import OVWMSLogo from '../logos/OV-WarrantyMS.png';
    import OVEventLogo from '../logos/OV-EventMgmt.png';
    import OVProfileLogo from '../logos/OVProfile.png';
    import OVSurewayLogo from '../logos/OV-Sureway.png';
    import OVNewsLogo from '../logos/OV-NewsLetter.png';
    import OVGuruLogo from '../logos/OVGuru.png';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer/Footer';

    const productLinks = {
        OVPlan: process.env.REACT_APP_OVPLAN_URL,
        OVTime: process.env.REACT_APP_OVTIME_URL,
        OVRetail: process.env.REACT_APP_OVRETAIL_URL,
        OVIMS: process.env.REACT_APP_OVIMS_URL,
        OVWMS: process.env.REACT_APP_OVWMS_URL,
        OVEvent: process.env.REACT_APP_OVEVENT_URL,
        OVProfile: process.env.REACT_APP_OVPROFILE_URL,
        OVSureway: process.env.REACT_APP_OVSUREWAY_URL,
        OVNews: process.env.REACT_APP_OVNEWS_URL,
        OVGuru: process.env.REACT_APP_OVGURU_URL,
    };


function ProductsList() {
    const handleImageClick = (link) => {
        if (link) {
            window.open(link, '_blank');
        } else {
            alert('URL not found for this product.');
        }
    };

    return (
        <>
        <NavBar/>

        <Typography variant='h5' sx={{mt:5, ml:5}}>
            <b>We are offering the range of Digital solutions through our SaaS platform. Click on below to explore more.</b>
        </Typography>
        <br/>
        <Divider sx={{ borderBottomWidth: 2, bgcolor: 'primary.main' }} />
        
        <Stack spacing={3} sx={{m:5}}>

            {/* OV Plan */}
            <Paper elevation={3} >
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVPlan)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVPlanLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Agile Project management tool </li>
                            <br />
                            <li> Plan, monitor and track Customize as a CRM, Queue management tool </li>
                            <br />
                            <li> Dashboard and analytics</li>
                            <br />
                            <li> Custom rules with automatic workflows</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Time */}
            <Paper elevation={3} sx={{bgcolor:'#b9d5fa'}}>
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVTime)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVTimeLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> A SaaS based Digital time tracking and reporting solution for a single location, muti-site or distributed teams </li>
                            <br />
                            <li> No hardware installation or usage involved</li>
                            <br />
                            <li> Easy to use application equally accessible to through Web app and / or Mobile app                            </li>
                            <br />
                            <li> Works for co-located as well as distributed teams</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Retail */}
            <Paper elevation={3} >
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVRetail)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVRetailLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Digitised solution for Professional, self employed, service and product business owners</li>
                            <br />
                            <li> Manage your contacts, services, appointments, walk-ins</li>
                            <br />
                            <li> Integrated with Inventory Management, Warranty Management, Invoice and Quotation modules</li>
                            <br />
                            
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Inventory */}
            <Paper elevation={3} sx={{bgcolor:'#b9d5fa'}}>
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVIMS)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVIMSLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Digitised Inventory Management Solution </li>
                            <br />
                            <li> Track your inventory utilisation </li>
                            <br />
                            <li> Transfer inventory items across locations to ensure end-to-end traceability</li>
                            <br />
                            <li> Multi-location Inventory Management solution</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Warranty */}
            <Paper elevation={3} >
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVWMS)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVWMSLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> An unique Digitised independent warranty management system</li>
                            <br />
                            <li> Track and manage your personal goods / services warranties received</li>
                            <br />
                            <li> Manage post sale warranties as Seller for better customer experience</li>
                            <br />
                            <li> Integrated complaint registration/resolution module</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Event */}
            <Paper elevation={3} sx={{bgcolor:'#b9d5fa'}}>
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVEvent)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVEventLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Host a new online / offline event</li>
                            <br />
                            <li> Add flyers, event details, bookings</li>
                            <br />
                            <li> Set calendar invites</li>
                            <br />
                            <li> Dashboard and analytics</li>
                            <br />
                            <li> Register for new events, get calendar view for better time management</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Profile */}
            <Paper elevation={3} >
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVProfile)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVProfileLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Digital Solution to manage your online business profile</li>
                            <br />
                            <li> Manage your contacts, visiting cards</li>
                            <br />
                            <li> Easy to share interface for connecting with more businesses through exchange of physical as well as Digital visiting cards</li>
                            
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Sureway */}
            <Paper elevation={3} sx={{bgcolor:'#b9d5fa'}}>
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVSureway)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVSurewayLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Digital solution using flexible form design to carry market research, conduct online assessments / exams</li>
                            <br />
                            <li> Power to user to design a custom form in just a click and go way</li>
                            <br />
                            <li> Capture real-time photos, location coordinates to improve user confidence on responses received</li>
                            
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV News */}
            <Paper elevation={3} >
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVNews)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVNewsLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> SaaS based Digital solution to publish daily, monthly, quarterly news or Blogs in your close community</li>
                            <br />
                            <li> Publish or share your daily, monthly, quarterly or ad hoc frequency news letter at the click of button</li>
                            <br />
                            <li> Features to improve readability across different digital forms news contents</li>
                            
                        </ul>
                    </Box>
                </Stack>
            </Paper>

            {/* OV Guru */}
            <Paper elevation={3} sx={{bgcolor:'#b9d5fa'}}>
                <Stack direction='row' spacing={2} sx={{ p: 3 }}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                border: '2px solid #1976d2',
                            },
                        }}
                        onClick={() => handleImageClick(productLinks.OVGuru)}
                    >
                        <CardMedia
                            component="img"
                            height="200"

                            image={OVGuruLogo}
                            alt={"OV Plan"}
                            sx={{ objectFit: 'contain', borderRadius: '10px' }}

                        />
                    </Card>

                    <Box sx={{ p: 2 }}>

                        <ul>
                            <li> Your online digital training platform for enhancing skills and improving your employability</li>
                            <br />
                            <li> Create your training contents as Guru and share it with your students as paid or unpaid contents</li>
                            <br />
                            <li> Track your training progress as a Student</li>
                            <br />
                            <li> Integrated online assessment</li>
                        </ul>
                    </Box>
                </Stack>
            </Paper>
        </Stack>
        <br/>
        <Footer/>
        <br/>
        </>
    );
}

export default ProductsList;