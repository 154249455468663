import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, Box } from '@mui/material';
import Footer from './components/Footer/Footer';
import ProductsList from './pages/ProductsList';
import CarouselComponent from './components/Carousel'; 
import Logo from "./logos/OmneVu logo.png";
import HomePg from './pages/HomePg';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TnCPg from './components/Footer/TnCPg';
import PrivyPolicy from './components/Footer/PrivyPolicy';
import ThirdPrtyPolicy from './components/Footer/ThirdPrtyPolicy';


function App() {
  return (
    <Router>
      <div>
        

        {/* <Container sx={{ marginTop: '20px' }}> */}
          <Routes>
            {/* <Route path="/" element={<CarouselComponent />} />  */}
            <Route path="/" element={<HomePg />} /> {/* Use HomePage with carousel */}
            <Route path="/products" element={<ProductsList />} />
            <Route path="/services" element={<h2>Services Page</h2>} />
            <Route path="/about" element={<AboutUs/>} />
            <Route path="/contact" element={<ContactUs/>} />
            <Route path="/tnc" element={<TnCPg/>} />
            <Route path="/privy" element={<PrivyPolicy/>} />
            <Route path="/3rd" element={<ThirdPrtyPolicy/>} />
            
          </Routes>
        {/* </Container> */}

       
      </div>
    </Router>
  );
}

export default App;
