import { Box, Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer/Footer";


export default function AboutUs(){
    return(
        <>
        <NavBar/>
        <Box sx={{m:5}}>
            <Typography variant="h6">
                We are a Team of Professional Software Engineers. 
                <p>We are focused on SaaS based solutions for B2B as well as B2C customers.</p>
            </Typography>
        </Box>
        <br/>
        <Footer></Footer>
        </>
    )
}