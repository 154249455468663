
// import React from 'react';
// import { Box, Typography, Link } from '@mui/material';
// import Grid from '@mui/material/Grid2';

// function Footer() {
//   return (
//     <Box sx={{ bgcolor: 'primary.main', color: 'white', padding: '20px 0', marginTop: 'auto' }}>
//       <Grid container spacing={25} justifyContent="center">
//         <Grid item>
//           <Typography variant="h6">Quick Links:</Typography>
//           <Link href="/" color="inherit">Home</Link><br />
//           <Link href="/products" color="inherit">Products</Link><br />
//           <Link href="/tnc" color="inherit" target='_blank'>Terms and Conditions</Link><br />
//           {/* <Link href="/services" color="inherit">Services</Link><br /> */}
//           {/* <Link href="/about" color="inherit">About Us</Link><br /> */}
//           {/* <Link href="/contact" color="inherit">Contact Us</Link> */}
//         </Grid>

//         <Grid item>
//           <Typography variant="h6">Contact Us:</Typography>
//           <Typography>Email: omnevu@gmail.com</Typography>
//           {/* <Typography>Phone: +123456789</Typography> */}
//         </Grid>

//         <Grid item>
//           <Typography variant="h6">Follow Us:</Typography>
//           <Link href="#" color="inherit">Facebook</Link><br />
//           <Link href="#" color="inherit">Twitter</Link><br />
//           <Link href="#" color="inherit">Instagram</Link>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default Footer;

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';

function Footer() {
  return (
    <Box 
      sx={{
        background: 'linear-gradient(to right, #007BFF, #00C6FF)', // Blue gradient background
        color: 'white',
        padding: '15px 0', // Reduced padding for a smaller height
        marginTop: 'auto',
        fontFamily: 'Poppins, sans-serif', // Font family change
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>Quick Links:</Typography>
          <Link href="/" color="inherit" sx={{ display: 'block', mb: 0.5 }}>Home</Link>
          <Link href="/products" color="inherit" sx={{ display: 'block', mb: 0.5 }}>Products</Link>
          <Link href="/tnc" color="inherit" target="_blank" sx={{ display: 'block', mb: 0.5 }}>Terms and Conditions</Link>
        </Grid>

        <Grid item>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>Contact Us:</Typography>
          <Typography sx={{ mb: 0.5 }}>Email: omnevu@gmail.com</Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>Follow Us:</Typography>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 0.5 }}>Facebook</Link>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 0.5 }}>Twitter</Link>
          <Link href="#" color="inherit" sx={{ display: 'block', mb: 0.5 }}>Instagram</Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
