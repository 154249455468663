import { Box, Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer/Footer";


export default function ContactUs(){
    return(
        <>
        <NavBar/>
        <Box sx={{m:5}}>
            <Typography variant="h6">
                We are based out Pune, India.
                <p>Please reach to us by writing to omnevu@gmail.com</p>
            </Typography>
        </Box>
        <br/>
        <Footer></Footer>
        </>
    )
}