// import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
// import CarouselComponent from "../components/Carousel";
// import Footer from '../components/Footer/Footer';

// import NavBar from "../components/NavBar";
// import HeroImage from "../logos/HeroImage.jpg";
// import { Link } from "react-router-dom";



// export default function HomePg() {

//     return (
//         <>
//             <NavBar />
//             <Box sx={{ m: 5 }}>
//                 <Typography variant="h4"><b>Your One-Stop Solution for Business Success</b></Typography>
//                 <Typography variant="h6">Streamline Your Operations, Elevate Your Brand</Typography>
//                 <br />
//                 <Divider sx={{ borderBottomWidth: 7, bgcolor: 'primary.main' }} />

//             </Box>
//             <Box component='img' src={HeroImage} alt='Group Image'
//                 sx={{
//                     height: "450px", width: '90%',
//                     ml: 5, mb: 3, border: 2
//                 }}></Box>
//             <br />
//             <Divider sx={{ borderBottomWidth: 3, bgcolor: 'primary.main' }} />
//             <p><Typography variant="h6" ml={5} color="primary.main"><b>Revolutionize Your Retail Operations:</b></Typography></p>

//             <CarouselComponent />
//             {/* <Divider sx={{ borderBottomWidth: 3, bgcolor: 'primary.main' }} /> */}
//             <Box
//                 sx={{ ml: 5, mt:3 }}>
//                 <p><b>Agile Project Planning:</b> Embrace flexibility and adaptability with our agile project management framework.</p>
//                 <p><b>Inventory Management:</b> Optimize stock levels, track product movements, and reduce costs with our advanced inventory system.</p>
//                 <p><b>Customer Profiles / Market Research / Assessments:</b> Gain deep insights into customer behavior, preferences, and purchase history to personalize your marketing efforts.</p>
//                 <p><b>Real-time News and Updates:</b> Stay ahead of industry trends and market fluctuations with our timely news feed.</p>
//                 <p><b>Efficient Time Booking:</b> Schedule appointments, allocate resources, and maximize productivity with our intuitive time booking tool.</p>

//                 <p><b>Skill Assessment and Development:</b> Identify talent gaps, upskill your team, and foster a culture of continuous learning.</p>
//                 <p><b>Warranty Management:</b> Simplify warranty claims, track product lifecycles, and enhance customer satisfaction.</p>
//             </Box>
//             <br />
//             <Divider sx={{ borderBottomWidth: 2, bgcolor: 'primary.main' }} />
//             <br />
//             <Stack direction="row" spacing={2} ml={5}>
//                 <Typography variant="h6" color="primary.main" ml={5}><b>Your Business, Reimagined:</b></Typography>


//                 <Link to="products"><b><Chip label='&nbsp; &nbsp; &nbsp;Explore More&nbsp; &nbsp; &nbsp;' color="success"></Chip></b></Link>
//             </Stack>
//             <br />
//             <Footer />
//         </>
//     )
// }

// import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
// import CarouselComponent from "../components/Carousel";
// import Footer from '../components/Footer/Footer';
// import NavBar from "../components/NavBar";
// import HeroImage from "../logos/HeroImage.jpg";
// import { Link } from "react-router-dom";
// import BuildIcon from '@mui/icons-material/Build';
// import InventoryIcon from '@mui/icons-material/Inventory';
// import PeopleIcon from '@mui/icons-material/People';
// import EventNoteIcon from '@mui/icons-material/EventNote';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import VerifiedIcon from '@mui/icons-material/Verified';

// export default function HomePg() {

//     const features = [
//         {
//             title: "Agile Project Planning",
//             description: "Embrace flexibility and adaptability with our agile project management framework.",
//             icon: <BuildIcon color="primary" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Inventory Management",
//             description: "Optimize stock levels, track product movements, and reduce costs with our advanced inventory system.",
//             icon: <InventoryIcon color="secondary" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Customer Profiles & Market Research",
//             description: "Gain deep insights into customer behavior, preferences, and purchase history to personalize your marketing efforts.",
//             icon: <PeopleIcon color="success" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Real-time News and Updates",
//             description: "Stay ahead of industry trends and market fluctuations with our timely news feed.",
//             icon: <EventNoteIcon color="error" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Efficient Time Booking",
//             description: "Schedule appointments, allocate resources, and maximize productivity with our intuitive time booking tool.",
//             icon: <EventNoteIcon color="primary" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Skill Assessment & Development",
//             description: "Identify talent gaps, upskill your team, and foster a culture of continuous learning.",
//             icon: <AssessmentIcon color="secondary" sx={{ fontSize: 50 }} />
//         },
//         {
//             title: "Warranty Management",
//             description: "Simplify warranty claims, track product lifecycles, and enhance customer satisfaction.",
//             icon: <VerifiedIcon color="success" sx={{ fontSize: 50 }} />
//         },
//     ];

//     return (
//         <>
//             <NavBar />

//             {/* Hero Section */}
//             <Box position="relative" sx={{ width: '100%', height: { xs: 300, md: 450 }, mb: 5 }}>
//                 <Box
//                     component="img"
//                     src={HeroImage}
//                     alt="Hero"
//                     sx={{
//                         width: "100%",
//                         height: "100%",
//                         objectFit: "cover",
//                         filter: "brightness(0.7)",
//                     }}
//                 />
//                 <Box
//                     position="absolute"
//                     top="50%"
//                     left="50%"
//                     sx={{ transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff' }}
//                 >
//                     <Typography variant="h3" fontWeight="bold" mb={2} sx={{ fontSize: { xs: "2rem", md: "3rem" } }}>
//                         Your One-Stop Solution for Business Success
//                     </Typography>
//                     <Typography variant="h5" sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
//                         Streamline Your Operations, Elevate Your Brand
//                     </Typography>
//                 </Box>
//             </Box>

//             {/* Carousel Section */}
//             <CarouselComponent />

//             {/* Divider */}
//             <Divider sx={{ borderBottomWidth: 7, bgcolor: 'primary.main', my: 5 }} />

//             {/* Features Section */}
//             <Box sx={{ px: { xs: 3, md: 8 }, mb: 5 }}>
//                 <Typography variant="h4" fontWeight="bold" color="primary.main" mb={3}>
//                     Revolutionize Your Retail Operations:
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {features.map((feature, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Box
//                                 sx={{
//                                     display: "flex",
//                                     flexDirection: "column",
//                                     alignItems: "center",
//                                     textAlign: "center",
//                                     p: 2,
//                                     border: "1px solid #ddd",
//                                     borderRadius: 2,
//                                     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
//                                 }}
//                             >
//                                 {feature.icon}
//                                 <Typography variant="h6" fontWeight="bold" mt={2}>
//                                     {feature.title}
//                                 </Typography>
//                                 <Typography variant="body1" mt={1} color="text.secondary">
//                                     {feature.description}
//                                 </Typography>
//                             </Box>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Box>

//             {/* Call to Action */}
//             <Stack direction="row" spacing={2} ml={5} mb={5}>
//                 <Typography variant="h6" color="primary.main" ml={5}><b>Your Business, Reimagined:</b></Typography>
//                 <Link to="products"><Chip label='Explore More' color="success" clickable /></Link>
//             </Stack>

//             <Divider sx={{ borderBottomWidth: 3, bgcolor: 'primary.main', mb: 5 }} />

//             {/* Footer */}
//             <Footer />
//         </>
//     );
// }



import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import CarouselComponent from "../components/Carousel";
import Footer from '../components/Footer/Footer';
import NavBar from "../components/NavBar";
import HeroImage from "../logos/HeroImage.jpg";
import { Link } from "react-router-dom";
import BuildIcon from '@mui/icons-material/Build';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import VerifiedIcon from '@mui/icons-material/Verified';
import RevolutionImage from "../logos/revolution.png";
export default function HomePg() {

    const features = [
        {
            title: "Agile Project Planning",
            description: "Embrace flexibility and adaptability with our agile project management framework.",
            icon: <BuildIcon color="primary" sx={{ fontSize: 50 }} />
        },
        {
            title: "Inventory Management",
            description: "Optimize stock levels, track product movements, and reduce costs with our advanced inventory system.",
            icon: <InventoryIcon color="secondary" sx={{ fontSize: 50 }} />
        },
        {
            title: "Customer Profiles & Market Research",
            description: "Gain deep insights into customer behavior, preferences, and purchase history to personalize your marketing efforts.",
            icon: <PeopleIcon color="success" sx={{ fontSize: 50 }} />
        },
        {
            title: "Real-time News and Updates",
            description: "Stay ahead of industry trends and market fluctuations with our timely news feed.",
            icon: <EventNoteIcon color="error" sx={{ fontSize: 50 }} />
        },
        {
            title: "Efficient Time Booking",
            description: "Schedule appointments, allocate resources, and maximize productivity with our intuitive time booking tool.",
            icon: <EventNoteIcon color="primary" sx={{ fontSize: 50 }} />
        },
        {
            title: "Skill Assessment & Development",
            description: "Identify talent gaps, upskill your team, and foster a culture of continuous learning.",
            icon: <AssessmentIcon color="secondary" sx={{ fontSize: 50 }} />
        },
        {
            title: "Warranty Management",
            description: "Simplify warranty claims, track product lifecycles, and enhance customer satisfaction.",
            icon: <VerifiedIcon color="success" sx={{ fontSize: 50 }} />
        },
    ];

    return (
        <>
            <NavBar />

            {/* Hero Section */}
            <Box position="relative" sx={{ width: '100%', height: { xs: 300, md: 450 }, mb: 5 }}>
                <Box
                    component="img"
                    src={HeroImage}
                    alt="Hero"
                    sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "brightness(0.7)",
                    }}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{ transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff' }}
                >
                    <Typography variant="h3" fontWeight="bold" mb={2} sx={{ fontSize: { xs: "2rem", md: "3rem" } }}>
                        Your One-Stop Solution for Business Success
                    </Typography>
                    <Typography variant="h5" sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                        Streamline Your Operations, Elevate Your Brand
                    </Typography>
                </Box>
            </Box>

            {/* Carousel Section */}
            <CarouselComponent />

            {/* Divider */}
            <Divider sx={{ borderBottomWidth: 7, bgcolor: 'primary.main', my: 5 }} />

            {/* Features Section */}
            <Box sx={{ px: { xs: 3, md: 8 }, mb: 5 }}>
    <Typography variant="h4" fontWeight="bold" color="primary.main" mb={3}>
        Revolutionize Your Retail Operations:
    </Typography>
    <Grid container spacing={3}>
        {/* Left Column - Image */}
        <Grid item xs={12} md={4}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Retain the shadow
                }}
            >
                <Box
                    component="img"
                    src={RevolutionImage}
                    alt="Revolutionize Retail"
                    sx={{
                        width: "100%",
                        maxWidth: "300px",
                        height: "auto",
                        objectFit: "contain",
                        filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))",
                    }}
                />
            </Box>
        </Grid>

        {/* Right Column - Features */}
        <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row", // Arrange logo and text horizontally
                                alignItems: "flex-start",
                                textAlign: "left",
                                p: 1.5, // Reduced padding
                                border: "1px solid #ddd",
                                borderRadius: 2,
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: 50, // Set a fixed width for icons
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {feature.icon}
                            </Box>
                            <Box ml={2}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    sx={{ fontSize: "0.95rem" }}
                                >
                                    {feature.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    mt={0.5}
                                    color="text.secondary"
                                    sx={{ fontSize: "0.85rem" }}
                                >
                                    {feature.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
</Box>



<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 5, mt: 3 }}>
    <Typography variant="h6" color="primary.main" mb={2} textAlign="center">
        <b>Your Business, Reimagined:</b>
    </Typography>
    <Link to="products">
        <Chip label="Explore More" color="success" clickable />
    </Link>
</Box>


            <Footer />
        </>
    );
}
